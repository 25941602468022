import { useCallback, useEffect, useState } from "react";
import { Bottom, Card, Container } from "./styles";
import { listProducts } from "../../services/products.service";
import { handleToast } from "../../utils/handleToast";
import { Link } from "react-router-dom";
import { formatPrice } from "../../utils/formatPrice";
import NoImage from "../../img/no-image-lg.png";
import { useAppSelector } from "../../hooks";
import ReactLoading from "react-loading";
import { Box, Chip } from "@mui/material";

const ProductsList = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined as any);
  const token = useAppSelector((state) => state.token);
  const address = useAppSelector((state) => state.address.data);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);

      try {
        const res = await listProducts({
          page: 1,
          rowsPerPage: 16,
          cep: address?.zipcode || address?.cep,
        });

        if (!(res as any).error) {
          setData(res?.data?.items);
        } else {
          handleToast(true, "Não foi possível trazer produtos");
        }
      } catch (e) {
        handleToast(true, "Não foi possível trazer produtos");
      } finally {
        setLoading(false);
      }
    };

    fetch();
  }, [address?.cep, address?.zipcode]);

  const renderTitleWithFilter = useCallback(
    (title: string, filters: string[]) => {
      const filterMap: Record<string, string> = {
        FILTER_OIL: "Óleo",
        FILTER_AIR: "Ar",
        FILTER_FUEL: "Combustível",
        FILTER_CABIN: "Cabine",
      };

      // Transforma o array de filtros em strings legíveis e une com " | "
      const mapFilters = filters
        .map((filter) => filterMap[filter] || filter)
        .join(" | ");

      if (mapFilters) return title.concat(" - ", mapFilters);

      return title;
    },
    []
  );

  if (loading) {
    return (
      <ReactLoading
        type={"bars"}
        color={"#1ea418"}
        className="loading-center"
        height="50px"
        width="50px"
      />
    );
  }

  return (
    <Container>
      {data?.map((item: any) => (
        <Card key={item._id}>
          <Link to={token ? `/item-produto/${item._id}` : "/login"}>
            {item.images != null ? (
              <img
                src={
                  item?.images[0]?.image64
                    ? `data:image/png;base64, ${item?.images[0]?.image64}`
                    : NoImage
                }
                alt={item?.title}
              />
            ) : (
              <img src={NoImage} alt={item?.title} />
            )}

            <Bottom>
              <h3>{renderTitleWithFilter(item.title, item.filterType)}</h3>
              <p className="price-over-time">
                {item?.installments || 1}x de{" "}
                {formatPrice(item.price / item?.installments || 1)}
              </p>
              <p className="total-price">{formatPrice(item.price)}</p>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  mt: "8px",
                }}
              >
                <Chip label={item.carName} />
                <Chip label={item.carBrand} />
              </Box>
            </Bottom>
          </Link>
        </Card>
      ))}
    </Container>
  );
};

export default ProductsList;

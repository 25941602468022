import { useCallback, useEffect, useState } from "react";
import Breadcrumb from "../../components/breadcrumb";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import LoadingPage from "../../components/LoadingPage";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { userShopCart } from "../../store/shop-cart";
import { Container, Right, Wrapper } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import { handleToast } from "../../utils/handleToast";
import api from "../../services/api";
import { addDays } from "date-fns";

const Pagamento: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state }: any = location;

  const dispatch = useAppDispatch();
  const shopCart = useAppSelector((state) => state.shopCart);
  const user = useAppSelector((state) => state.user);

  const [loading, setLoading] = useState({ shopCart: true, pix: true });
  const [pixData, setPixData] = useState(null as any);
  const [idPay, setIdPay] = useState("");

  /** 1) pega os itens do carrinho de compra */
  useEffect(() => {
    const fetch = async () => {
      if (!shopCart.data) {
        setLoading((prevState) => ({ ...prevState, shopCart: true }));
        await dispatch(userShopCart());
      }
      setLoading((prevState) => ({ ...prevState, shopCart: false }));
    };

    fetch();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** 2) envia a requisição para capturar o qrCode   */
  const handlePayment = useCallback(async () => {
    setLoading((prevState) => ({ ...prevState, pix: true }));

    const res = await api.post(
      `/create-billing?orgId=${shopCart?.data?.items?.organization._id}`,
      {
        data: {
          billingType: "PIX",
          dueDate: addDays(new Date(), 2),
          value: shopCart?.data?.items?.total,
          description: `${shopCart?.data?.items?._id}`,
          name: state.name,
          cpfCnpj: state.cpfCnpj,
          customer: user?.data?.user?.costumerId,
          customerEmail: user.data.user.email,
          address: state.address,
        },
      }
    );

    if (res.data.error) {
      if (
        Array.isArray(res?.data?.message) &&
        typeof res?.data?.message !== "string"
      )
        res?.data?.message?.forEach((msg: any) => {
          handleToast(true, msg);
        });
      else handleToast(true, res?.data?.message);

      return navigate("/compra", {
        state: {
          billingType: "PIX",
          name: state.name,
          cpfCnpj: state.cpfCnpj,
          address: state.address,
        },
      });
    }

    setIdPay(res?.data?.data?.id_pay);

    setPixData(res?.data?.data?.QrCode);

    setLoading((prevState) => ({ ...prevState, pix: false }));
  }, [
    navigate,
    shopCart?.data?.items,
    user.data.user?.costumerId,
    user.data.user.email,
    state,
  ]);

  useEffect(() => {
    if (!loading.shopCart && shopCart?.data?.items && state?.pix) {
      handlePayment();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading.shopCart]);

  useEffect(() => {
    const fetch = async () => {
      if (idPay) {
        setInterval(async () => {
          await api.get(`/consult-payment/${idPay}`);
        }, 5000);
      }
    };

    fetch();
  }, [idPay]);

  useEffect(() => {
    if (!loading.shopCart && !shopCart?.data?.items) {
      handleToast(
        true,
        "Você deve ter itens no carrinho para finalizar a compra."
      );
      // navigate("/compra");
    }

    if (!state?.pix) {
      handleToast(true, "Operação não permitida.");
      navigate("/compra", {
        state: {
          billingType: "PIX",
          name: state.name,
          cpfCnpj: state.cpfCnpj,
          address: state.address,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, navigate, shopCart, state?.pix]);

  if (loading.shopCart || loading.pix) {
    return <LoadingPage />;
  }

  return (
    <>
      <Header />
      <Breadcrumb />
      <Wrapper>
        <Container>
          <div className="top">
            {" "}
            <h2>Pagamento em Pix</h2>
          </div>
          <div className="bottom">
            <p>
              Você pode utilizar a câmera do seu celular para ler o QR Code ou
              copiar o código e pagar no aplicativo de seu banco:
            </p>
            <div>
              {" "}
              <img
                src={"data:image/png;base64, " + pixData?.encodedImage}
                alt="QrCode"
              />
              <div className="code">
                <textarea value={pixData?.payload || ""} readOnly />
                <button>Copiar código</button>
              </div>
            </div>
          </div>
        </Container>
        <Right>
          <span>Forma de pagamento</span>
          <p>Pix</p>
          <span>Tempo de processamento</span>
          <p>1 minuto</p>
          <div className="bottom">
            Total a pagar: <span>R${shopCart?.data?.items?.total}</span>
          </div>
        </Right>
      </Wrapper>
      <Footer />
    </>
  );
};

export default Pagamento;
